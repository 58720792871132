import { Injectable } from '@angular/core';
import { mainData } from '../../shared/interface/main-data';
import { ILibLocalStorage } from '../public-api';
@Injectable()
export class LibLocalStorageService implements ILibLocalStorage {
  private storageKey: string = 'esunbank_token';
  getApiToken(): string {
    this.mainData$.loading$.next(true);
    let info = `${localStorage.getItem(this.storageKey)}`;
    return info;
  }

  setApiToken(token: any): void {
    localStorage.setItem(this.storageKey, token);
  }

  clearApiToken(): void {
    localStorage.removeItem(this.storageKey);
  }

  constructor(
    public mainData$: mainData,
  ) { }
}
