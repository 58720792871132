const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const oneMonthsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 1);
const threeMonthsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 3);
const sixMonthsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 6);
const oneYearAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 12);
const twoYearsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 24);
const threeYearsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 36);
const fiveYearsAgo = new Date(currentDate).setMonth(currentDate.getMonth() - 60);
export const enumDateList = [
    {
        tickInterval: 30,
        tickIntervalM: 100,
        Name: '今年以來',
        SDate: `${currentYear}-01-01`,
        EDate: currentDate,
    },{
        tickInterval: 2,
        tickIntervalM: 5,
        Name: '3個月',
        SDate: threeMonthsAgo,
        EDate: currentDate,
    },{
        tickInterval: 16,
        tickIntervalM: 60,
        Name: '6個月',
        SDate: sixMonthsAgo,
        EDate: currentDate,
    },{
        tickInterval: 30,
        tickIntervalM: 200,
        Name: '1年',
        SDate: oneYearAgo,
        EDate: currentDate,
    },{
        tickInterval: 60,
        tickIntervalM: 200,
        Name: '2年',
        SDate: twoYearsAgo,
        EDate: currentDate,
    },{
        tickInterval: 100,
        tickIntervalM: 500,
        Name: '3年',
        SDate: threeYearsAgo,
        EDate: currentDate,
    },{
        tickInterval: 280,
        tickIntervalM: 500,
        Name: '5年',
        SDate: fiveYearsAgo,
        EDate: currentDate,
    },{
        tickInterval: 300,
        tickIntervalM: 780,
        Name: '成立至今',
        SDate: null,
        EDate: currentDate,
    }
]

export const enumETFDateList = [
    {
        tickInterval: 2,
        tickIntervalM: 100,
        Name: '近30天',
        SDate: oneMonthsAgo,
        EDate: currentDate,
    },{
        tickInterval: 8,
        tickIntervalM: 5,
        Name: '近3個月',
        SDate: threeMonthsAgo,
        EDate: currentDate,
    },{
        tickInterval: 10,
        tickIntervalM: 30,
        Name: '近6個月',
        SDate: sixMonthsAgo,
        EDate: currentDate,
    },{
        tickInterval: 20,
        tickIntervalM: 60,
        Name: '近1年',
        SDate: oneYearAgo,
        EDate: currentDate,
    },{
        tickInterval: 30,
        tickIntervalM: 500,
        Name: '成立至今',
        SDate: 0,
        EDate: currentDate,
    }
]
