import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export abstract class ILibHttpApi {
    abstract Api(apiName: string): ILibHttp;
    abstract ApiDownload(apiName: string): ILibHttp;
}

export abstract class ILibHttp {
    abstract setHeader(key: any, value: any): any;
    abstract get<T>(params?: any): Observable<T>;
    abstract post<T>(data: any): Observable<T>;
    abstract update<T>(data: any): Observable<T>;
    abstract delete<T>(id: any): Observable<T>;
}

