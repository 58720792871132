import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-download',
  templateUrl: './form-download.component.html',
  styleUrls: ['./form-download.component.scss']
})
export class FormDownloadComponent {
  @Input() data: any[] = [];
  @Input() subData: any[] = []; //子選單選項
  @Input() isActiveSubId:number = -1;
  @Output() onChange = new EventEmitter<any>();
  // fakeData: string[] = ['街口投信系列基金', 'ETF 系列', '資本集團系列基金'];
  selectedData: string | null = null;

  selectData(data: any) {
    this.selectedData = data;
    this.onChange.emit(data)
  }
}
