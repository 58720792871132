import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/modules/shared.module';
import { CommonModule } from "@angular/common";
import { MainDataService } from './shared/services/main-data.service';
import { mainData } from './shared/interface/main-data';

//套件Module
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HighchartsChartModule } from 'highcharts-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DatePipe } from '@angular/common';
//primeng
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';

//API
import { HttpClientModule } from '@angular/common/http';
import { MyLibraryModule } from './api/public-api';

//component
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { HeaderComponent } from './views/base/header/header.component';
import { FooterComponent } from './views/base/footer/footer.component';
import { BannerCarouselComponent } from './shared/components/banner-carousel/banner-carousel.component';
import { ProductCarouselComponent } from './shared/components/product-carousel/product-carousel.component';
import { BreadCrumbComponent } from './views/base/bread-crumb/bread-crumb.component';
import { FundListComponent } from './views/product/fund-list/fund-list.component';
import { FundSearchComponent } from './shared/components/fund-search/fund-search.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { FundInfoComponent } from './views/product/fund-info/fund-info.component';
import { ProductInfoComponent } from './shared/components/product-info/product-info.component';
import { FundComposeComponent } from './shared/components/fund-compose/fund-compose.component';
import { FundPerformanceComponent } from './shared/components/fund-performance/fund-performance.component';
import { FundRecordComponent } from './shared/components/fund-record/fund-record.component';
import { NewsInfoComponent } from './shared/components/news-info/news-info.component';
import { NewsListComponent } from './views/notice/news-list/news-list.component';
import { WorldlistComponent } from './views/point/world-list/world-list.component';
import { AnnouncementInfoComponent } from './shared/components/announcement-info/announcement-info.component';
import { DividendListComponent } from './views/product/dividend-list/dividend-list.component';
import { WeeklyReportComponent } from './views/point/weekly-report/weekly-report.component';
import { QuarterlyComponent } from './views/point/quarterly/quarterly.component';
import { YieldListComponent } from './views/notice/yield-list/yield-list.component';
import { NewsInfoDetailComponent } from './shared/components/news-info-detail/news-info-detail.component';
import { AntiMoneyListComponent } from './views/notice/anti-money-list/anti-money-list.component';
import { QAlistComponent } from './views/qalist/qalist.component';
import { QAInfoComponent } from './shared/components/qa-info/qa-info.component';
import { FormDownloadComponent } from './shared/components/form-download/form-download.component';
import { StewardshipComponent } from './views/notice/stewardship/stewardship.component';
import { DownloadComponent } from './views/service/download/download.component';
import { ContactFormComponent } from './views/service/contact-form/contact-form.component';
import { ContactInfoComponent } from './shared/components/contact-info/contact-info.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { DisclaimerDialogComponent } from './shared/components/disclaimer-dialog/disclaimer-dialog.component';

//動態列表
import { UnitListComponent } from './views/unit/unit-list/unit-list.component';
import { UnitDetailComponent } from './views/unit/unit-detail/unit-detail.component';

//ETF
import { EtfListComponent } from './views/ETF/product/etf-list/etf-list.component';
import { EtfInfoComponent } from './views/ETF/product/etf-info/etf-info.component';
import { EtfIndicesComponent } from './views/ETF/product/etf-indices/etf-indices.component';
import { NavRealtimeComponent } from './views/ETF/product/nav-realtime/nav-realtime.component';
import { EtfProviderComponent } from './views/ETF/product/etf-provider/etf-provider.component';
import { EtfPcfComponent } from './views/ETF/product/etf-pcf/etf-pcf.component';
import { EtfNavComponent } from './views/ETF/product/etf-nav/etf-nav.component';
import { EtfConstituentComponent } from './views/ETF/product/etf-constituent/etf-constituent.component';
import { RegisterComponent } from './views/ETF/register/register/register.component';
import { ProcessComponent } from './views/ETF/register/process/process.component';
import { InquireComponent } from './views/ETF/register/inquire/inquire.component';
import { EsgListComponent } from './views/about-us/esg-list/esg-list.component';
import { IntroComponent } from './views/about-us/intro/intro.component';
import { EtfClassroomComponent } from './views/ETF/classroom/etf-classroom/etf-classroom.component';
import { TradInfoComponent } from './views/ETF/trad-info/trad-info/trad-info.component';
import { LectureComponent } from './views/fund-lecture/lecture/lecture.component';
import { LectureDetailComponent } from './views/fund-lecture/lecture-detail/lecture-detail.component';
import { LectureInfoComponent } from './shared/components/lecture-info/lecture-info.component';
import { LectureApplyComponent } from './views/fund-lecture/lecture-apply/lecture-apply.component';
import { AllSearchComponent } from './views/all-search/all-search.component';
import { UnitAnnouncementComponent } from './views/unit/unit-announcement/unit-announcement.component';
import { UnitNewsComponent } from './views/unit/unit-news/unit-news.component';
import { IndexMarketComponent } from './views/index-market/index-market.component';
//無障礙專區
import { AccessibleHomeComponent } from './views/accessibility/accessible-home/accessible-home.component';
import { HeaderAccessibleComponent } from './views/accessibility/header/header.component';
import { FooterAccessibleComponent } from './views/accessibility/footer/footer.component';

//活動頁
import { EventSiteComponent } from './views/event-site/event-site.component';
//活動表單
import { ActivityComponent } from './views/activity/activity.component';
import { CalendarComponent } from './views/calendar/calendar.component';


//要用 primeng 加在這
@NgModule({
  exports: [
    PanelModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    TabViewModule,
    CheckboxModule,
    TableModule,
    RadioButtonModule,
    AutoCompleteModule,
    PaginatorModule
  ],
})
export class PrimengModule {}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BannerCarouselComponent,ProductCarouselComponent,
    BreadCrumbComponent,
    FundListComponent,FundSearchComponent,
    MessageDialogComponent,ConfirmDialogComponent,
    FundInfoComponent,ProductInfoComponent,FundComposeComponent,FundPerformanceComponent,FundRecordComponent,
    NewsInfoComponent,NewsListComponent,WorldlistComponent,AnnouncementInfoComponent,DividendListComponent,
    WeeklyReportComponent,QuarterlyComponent,YieldListComponent,NewsInfoDetailComponent,AntiMoneyListComponent,QAlistComponent,QAInfoComponent
    ,FormDownloadComponent,StewardshipComponent,DownloadComponent,ContactFormComponent,ContactInfoComponent,EtfListComponent,EtfInfoComponent,EsgListComponent,
    IntroComponent,EtfIndicesComponent,NavRealtimeComponent,EtfProviderComponent,EtfPcfComponent,EtfNavComponent,EtfConstituentComponent,RegisterComponent,
    ProcessComponent,InquireComponent,EtfClassroomComponent,TradInfoComponent,
    AccessibleHomeComponent,HeaderAccessibleComponent, FooterAccessibleComponent,
    UnitListComponent,UnitDetailComponent,LectureComponent,LectureDetailComponent,LectureInfoComponent,LectureApplyComponent,AllSearchComponent,
    LoadingComponent,
    EventSiteComponent,ActivityComponent,UnitAnnouncementComponent,UnitNewsComponent,DisclaimerDialogComponent,CalendarComponent,IndexMarketComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyLibraryModule,
    HttpClientModule,
    CarouselModule,
    PrimengModule,
    FormsModule,ReactiveFormsModule,
    HighchartsChartModule,FullCalendarModule,
    SharedModule,
    CommonModule,
    DatePipe
  ],
  providers: [{
    provide: mainData,
    useClass: MainDataService,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
