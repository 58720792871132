<div class="wrapper">
  <main>
    <div class="classbox">
      <div class="butn" *ngFor="let data of subData"   [ngClass]="{'selected': isActiveSubId === data.CId}" (click)="selectData(data)">{{data.CName}}</div>
  </div>
    <div class="detail-table-wrap">
      <div class="detail-table">
        <div class="item" *ngFor="let item of data" >
          <div class="label"><a href="{{item.FilePath}}" target="_blank"><img src="/assets/images/download_icon.svg" alt="pdf-download"></a>{{item.CName}}</div>
        </div>
        <!-- <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div>
        <div class="item">
          <div class="label"><img src="/assets/images/download_icon.svg" alt="pdf-download">[A-3]現有客戶升級全方位理財帳戶約定書</div>
        </div> -->
      </div>
    </div>
  </main>
</div>
