import { Component, HostListener } from '@angular/core';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-accessible-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderAccessibleComponent {
  @HostListener('window:resize', ['$event'])
  dropMenuShow = false;
  bgShow = false;
  currentMenu: number | null = null;
  open = false; //手機板漢堡選單開合狀態
  isDropdownOpen= false;
  searchopen = false;

  constructor(
    public homeService: HomeService,
  ) {
  }

  onResize() {
    let scrWidth = window.innerWidth;
    if (scrWidth >= 1024) {
      this.open = false;
    }
  }

  hideDropdown() {
    this.currentMenu = null; // 隐藏下拉菜单
  }
  getMenuId(menuId: number) {
    this.currentMenu = menuId;
    this.bgShow = true;
    this.dropMenuShow = true;
  }

  bgClose() {
    this.bgShow = false;
    this.currentMenu=0;
    // this.currentMenu = menuId;
  }

}
