import { environment } from '../../../src/environments/environment';

export const LibHttpUrl = {
  //固定參數
  Client: 'Member',
  Common: 'Common',
  Product: 'Product',
  ProductCategory: 'ProductCategory',
  Banner: 'Banner',
  NewsPage: 'NewsPage',
  Question: 'Question',
  ConsumerPage: 'ConsumerPage',
  Order: 'Order',
  OfflinePage: 'OfflinePage',
  MemberFavorite: 'MemberFavorite',
  Menu: 'Home',
  Category: 'Category',
  Fund: 'Fund',
  Hot: 'HotRecommendationPage',
  ConveniencePage: 'ConveniencePage',
  Confirmation: 'Confirmation',
  Aritle:'Article',
  Activity:'Activity',
  User:'User',
  Purchase: 'Purchase',
  apiUrl: `${environment.apiUrl}`,
  Auth: 'Auth',
  DocDownload: 'DocDownload',
  Event: 'Event'
};
