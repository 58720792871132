import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface DialogData {
 title:string;
 message:string;
 data: any;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() data:DialogData = {} as DialogData;
  @Input() visible:boolean = false;
  @Input() IsPreview:boolean = false;
  @Output() visibleChange = new EventEmitter<any>();
  @Output() unCheck = new EventEmitter();
  @Input() noagree:boolean = false;
  @Input() privateType:number = 1;
  constructor(
  ) {
    // this.visible = this.data.visible;
  }

  _visibleChange(visible:boolean) {
    this.visibleChange.emit(visible)
    this.visible = false;
  }

  unCheckClick() {
    this.unCheck.emit();
    this.noagree = true;
  }

}
