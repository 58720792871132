import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { FundListComponent } from './views/product/fund-list/fund-list.component';
import { FundInfoComponent } from './views/product/fund-info/fund-info.component';
import { NewsListComponent } from './views/notice/news-list/news-list.component';
import { WorldlistComponent } from './views/point/world-list/world-list.component';
import { DividendListComponent } from './views/product/dividend-list/dividend-list.component';
import { WeeklyReportComponent } from './views/point/weekly-report/weekly-report.component';
import { QuarterlyComponent } from './views/point/quarterly/quarterly.component';
import { YieldListComponent } from './views/notice/yield-list/yield-list.component';
import { NewsInfoDetailComponent } from './shared/components/news-info-detail/news-info-detail.component';
import { CalendarComponent } from './views/calendar/calendar.component';
import { AntiMoneyListComponent } from './views/notice/anti-money-list/anti-money-list.component';
import { QAlistComponent } from './views/qalist/qalist.component';
import { StewardshipComponent } from './views/notice/stewardship/stewardship.component';
import { DownloadComponent } from './views/service/download/download.component';
import { ContactFormComponent } from './views/service/contact-form/contact-form.component';

//ETF
import { EtfListComponent } from './views/ETF/product/etf-list/etf-list.component';
import { EtfInfoComponent } from './views/ETF/product/etf-info/etf-info.component';
import { RegisterComponent } from './views/ETF/register/register/register.component';
import { ProcessComponent } from './views/ETF/register/process/process.component';
import { InquireComponent } from './views/ETF/register/inquire/inquire.component';
import { EsgListComponent } from './views/about-us/esg-list/esg-list.component';
import { IntroComponent } from './views/about-us/intro/intro.component';
import { EtfClassroomComponent } from './views/ETF/classroom/etf-classroom/etf-classroom.component';
import { TradInfoComponent } from './views/ETF/trad-info/trad-info/trad-info.component';

//動態列表
import { UnitListComponent } from './views/unit/unit-list/unit-list.component';
import { UnitDetailComponent } from './views/unit/unit-detail/unit-detail.component';
import { LectureComponent } from './views/fund-lecture/lecture/lecture.component';
import { LectureDetailComponent } from './views/fund-lecture/lecture-detail/lecture-detail.component';
import { LectureApplyComponent } from './views/fund-lecture/lecture-apply/lecture-apply.component';
import { AllSearchComponent } from './views/all-search/all-search.component';

//活動頁
import { EventSiteComponent } from './views/event-site/event-site.component';
//活動表單
import { ActivityComponent } from './views/activity/activity.component';
import { IndexMarketComponent } from './views/index-market/index-market.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'product',
        title: '基金總覽 - 街口投信',
        children: [
          {
            path: 'fund-performance',
            component: FundListComponent,
            title: '基金績效 - 街口投信'
          },
          {
            path: 'fund-value',
            component: FundListComponent,
            title: '基金淨值 - 街口投信'
          },
          {
            path: 'fund-list',
            component: FundListComponent,
            title: '基金總覽 - 街口投信'
          },
          {
            path: 'yield-info',
            component: DividendListComponent,
            title: '配息資訊 - 街口投信'
          },
          {
            path: 'dividend-element',
            component: DividendListComponent,
            title: '配息組成 - 街口投信'
          },{
            path: 'reference-date',
            component: DividendListComponent,
            title: '配息基準日 - 街口投信'
          },
          {
            path: 'calendar',
            component: CalendarComponent,
            title: '休市日期 - 街口投信'
          },
          {
            path: 'etf-list',
            component: EtfListComponent,
            title: 'ETF總覽 - 街口投信'
          },
          {
            path: 'fund-info',
            component: FundInfoComponent,
            // title: '基本資料 - 街口投信'
          },
          {
            path: 'fund-compose',
            component: FundInfoComponent,
            // title: '投資組合 - 街口投信'
          },
          {
            path: 'net-performance',
            component: FundInfoComponent,
            // title: '績效淨值 - 街口投信'
          },
          {
            path: 'dividend-record',
            component: FundInfoComponent,
            // title: '配息紀錄 - 街口投信'
          },
        ]
      },
      {
        path: 'fund-info/:fund',
        component: FundInfoComponent,
        // title: '基本資料 - 街口投信'
      },
      {
        path: 'etf',
        // title: '基金總覽 - 街口投信',
        // component: EtfInfoComponent,
        children: [
          {
            path: 'etf-info/:id',
            component: EtfInfoComponent,
            // title: '基本介紹 - 街口投信'
          },{
            path: 'etf-indices/:id',
            component: EtfInfoComponent,
            // title: '指數介紹 - 街口投信'
          },
          {
            path: 'index-market/:id',
            component: IndexMarketComponent,
            title: '指數行情 - 街口投信'
          },
          {
            path: 'nav-realtime/:id',
            component: EtfInfoComponent,
            // title: '預估淨值vs即時市價 - 街口投信'
          },
          {
            path: 'etf-nav/:id',
            component: EtfInfoComponent,
            // title: '淨值VS收盤價 - 街口投信'
          },
          {
            path: 'etf-constituent/:id',
            component: EtfInfoComponent,
            // title: '基金成分股 - 街口投信'
          },
          {
            path: 'etf-pcf/:id',
            component: EtfInfoComponent,
            // title: '申購買回清單 - 街口投信'
          },
          {
            path: 'etf-provider/:id',
            component: EtfInfoComponent,
            // title: '流動量提供與參與券商 - 街口投信'
          },
          {
            path: 'predict',
            component: TradInfoComponent,
            title: '即時預估 - 街口投信'
          },
          {
            path: 'trace',
            component: TradInfoComponent,
            title: '追蹤差距 - 街口投信'
          },
          {
            path: 'bodong',
            component: TradInfoComponent,
            title: '變動率 - 街口投信'
          },
          {
            path: 'rate/:id',
            component: TradInfoComponent,
            title: '交易費率 - 街口投信'
          },
          {
            path: 'classroom',
            component: EtfClassroomComponent,
            title: 'ETF小教室 - 街口投信'
          },
           {
            path: 'classroom-detail/:id',
            component: NewsInfoDetailComponent,
            title: 'ETF小教室 - 街口投信'
          },
          {
            path: 'video',
            component: EtfClassroomComponent,
            title: 'ETF影音 - 街口投信'
          },
          {
            path: 'video-detail/:id',
            component: NewsInfoDetailComponent,
            title: 'ETF影音 - 街口投信'
          },
          {
            path: 'intro',
            component: EtfClassroomComponent,
            title: '懶人包下載 - 街口投信'
          },
          {
            path: 'intro-detail/:id',
            component: NewsInfoDetailComponent,
            title: '懶人包下載 - 街口投信'
          },
          {
            path: 'register/:id',
            component: RegisterComponent,
            title: 'ETF申購登記 - 街口投信'
          },{
            path: 'register/:id',
            component: RegisterComponent,
            title: 'ETF申購登記 - 街口投信'
          },
          {
            path: 'register/:id',
            component: RegisterComponent,
            title: 'ETF申購登記 - 街口投信'
          },
          {
            path: 'register/:id',
            component: RegisterComponent,
            title: 'ETF申購登記 - 街口投信'
          },
          {
            path: 'inquire',
            component: InquireComponent,
            title: '申購查詢/取消 - 街口投信'
          },
          {
            path: 'process/:id',
            component: ProcessComponent,
            title: '申購作業流程 - 街口投信'
          },
        ]
      },
      {
        path: 'point',
        title: '觀點 - 街口投信',
        children: [
          {
            path: 'news',
            component: WorldlistComponent,
            title: '全球日訊 - 街口投信'
          },
          {
            path: 'world-detail/:id',
            component: NewsInfoDetailComponent,
            title: '全球日訊 - 街口投信'
          },
          {
            path: 'weekly-report',
            component: WeeklyReportComponent,
            title: '市場週報 - 街口投信'
          },
          {
            path: 'weekly-report-detail/:id',
            component: NewsInfoDetailComponent,
            title: '市場週報 - 街口投信'
          },
          {
            path: 'quarterly-report',
            component: QuarterlyComponent,
            title: '投資季展望 - 街口投信'
          },
          {
            path: 'quarterly-report-detail/:id',
            component: NewsInfoDetailComponent,
            title: '投資季展望 - 街口投信'
          },
        ]
      },
      {
        path: 'notice',
        title: '公告 - 街口投信',
        children: [
          {
            path: 'bulletin',
            component: NewsListComponent,
            title: '最新消息 - 街口投信'
          },
          {
            path: 'bulletin-detail/:id',
            component: NewsInfoDetailComponent,
            title: '最新消息 - 街口投信'
          },
          {
            path: 'yield-notice',
            component: YieldListComponent,
            title: '配息公告 - 街口投信'
          },
          {
            path: 'yield-detail/:id',
            component: NewsInfoDetailComponent,
            title: '配息公告 - 街口投信'
          },
          {
            path: 'anti-money',
            component: AntiMoneyListComponent,
            title: '洗錢防制 - 街口投信'
          },
          {
            path: 'anti-money-detail/:id',
            component: NewsInfoDetailComponent,
            title: '洗錢防制 - 街口投信'
          },
          {
            path: 'stewardship',
            component: StewardshipComponent,
            title: '盡職治理 - 街口投信'
          },
        ]
      },
      {
        path: 'service',
        title: '服務 - 街口投信',
        children: [
          {
            path: 'qa',
            component: QAlistComponent,
            title: '常見問題 - 街口投信'
          },
          {
            path: 'download-form',
            component: DownloadComponent,
            title: '表單下載 - 街口投信'
          },
          {
            path: 'contact',
            component: ContactFormComponent,
            title: '客服信箱 - 街口投信'
          },
          {
            path: 'fund-lecture',
            component: LectureComponent,
            title: '基金講座 - 街口投信'
          },
          {
            path: 'fund-lecture/detail/:id',
            component: LectureDetailComponent,
            title: '基金講座 - 街口投信'
          },
          {
            path: 'fund-lecture/apply/:id',
            component: LectureApplyComponent,
            title: '基金講座 - 街口投信'
          },
        ]
      },
      {
        path: 'about-us',
        title: '我們 - 街口投信',

        children: [
          {
            path: 'intro/:id',
            component: IntroComponent,
            title: '街口投信'
          },
          {
            path: 'esg',
            component: EsgListComponent,
            title: '永續發展 - 街口投信'
          },
          {
            path: 'esg-detail/:id',
            component: NewsInfoDetailComponent,
            title: '永續發展 - 街口投信'
          },
          {
            path: 'job',
            component: FundListComponent,
            title: '加入我們 - 街口投信'
          },
          {
            path: 'appeals',
            component: ContactFormComponent,
            title: '檢舉管理 - 街口投信'
          },
        ]
      },
      {
        path: 'unit',
        title: '街口投信',
        children: [
          {
            path: ':Type/:FId/:CId',
            component: UnitDetailComponent,
            title: '街口投信'
          },
          {
            path: ':Type/:FId',
            component: UnitListComponent,
            title: '街口投信'
          },
        ]
      },
      {
        path: 'activity/:FormType',
        component: ActivityComponent,
        title: '活動資料 - 街口投信'
      },
      {
        path: 'events/:EventNo',
        title: '活動頁面 - 街口投信',
        component: EventSiteComponent,
      },
      {
        path: 'accessibility',
        title: '網站導覽 - 街口投信',
        loadChildren: () =>
        import("./views/accessibility/accessibility.module").then(
          (mod) => mod.AccessibleModule
        ),
      },
      {
        path: 'search',
        component: AllSearchComponent,
        title: '全站搜尋 - 街口投信'
      },
      {
        path: '',
        component: HomeComponent,
        title: '首頁 - 街口投信'
      },
    ]
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
    initialNavigation: 'enabledBlocking',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
