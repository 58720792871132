<p-dialog [closeOnEscape]="false" closeIcon="none" header="Header" [(visible)]="visible"
[style]="{'width': '800px', 'min-height': privateType === 1 ? '50%' : 'none'}" [breakpoints]="{ '960px': '85vw','460px': '90vw' }">
    <ng-template pTemplate="header">
        <span class="title">{{data.title}}</span>
    </ng-template>
    <div class="dialog-content" [innerHTML]="data.message|html">
    </div>

    <ng-template pTemplate="footer">
      <div class="agreebox">
        <div class="noagree" *ngIf="noagree">*如選擇不同意將無法繼續，請選擇同意</div>
        <div style="display: flex;justify-content: center;">
          <button *ngIf="data.data.ButtonStatus === 1" class="disagree"
            (click)="!IsPreview && unCheckClick()">不同意</button>
          <button *ngIf="data.data.ButtonStatus === 3" class="disagree"
            (click)="!IsPreview && _visibleChange(false)">取消</button>
          <button (click)="!IsPreview && _visibleChange(true)">{{data.data.ButtonStatus === 1 ? '同意' : '確認'}}</button>
        </div>
      </div>
    </ng-template>
</p-dialog>
