<div class="b-wrapper">
  <owl-carousel-o [options]="customOptions" (changed)="getData($event)" #owlCar1>
    <ng-container *ngFor="let slide of data">
      <ng-template carouselSlide>
        <a [href]="slide.CUrl" (click)="SaveClickCount(slide.CId)" [target]="slide.CIsOpenNewWindow ? '_blank' : 'self'">
          <img class="banner" [src]="slide.CPcfileName" [alt]="slide.CName"/>
          <img class="banner-m" [src]="slide.CMobileFileName" [alt]="slide.CName"/>
        </a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <!-- <div class="icon prev" (click)="owlCar1.prev()">
    <img src="./assets/banner-arrow_left.svg" />
  </div>
  <div class="icon next" (click)="owlCar1.next()">
    <img src="./assets/banner-arrow.svg" />
  </div> -->
</div>