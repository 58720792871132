<app-header *ngIf="!baseService.Accessible$.value && eventsService.isShow.header"></app-header>
<app-loading></app-loading>
<app-accessible-header *ngIf="baseService.Accessible$.value"></app-accessible-header>
<div id="outlet">
    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="!baseService.Accessible$.value"></app-footer>
<app-accessible-footer *ngIf="baseService.Accessible$.value"></app-accessible-footer>
<div id="go-top" class="top-icon opened" (click)="goTop()">
    <img src="./../assets/images/top-arrow.svg" alt="top-arrow" />
</div>
