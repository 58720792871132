import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ILibHttp, ILibHttpApi } from '../interface/lib-http.interface';
import { ILibHandleHttpError } from '../interface/lib-handle-http-error.interface';
import { LibHttpUrl } from '../http-url';
import { mainData } from '../../shared/interface/main-data';

@Injectable({
  providedIn: 'root'
})

export class HttpApi implements ILibHttpApi {
  constructor(
    private http: HttpClient,
    private hhe: ILibHandleHttpError,
    public mainData$: mainData,
  ) { }
  Api(apiName: string): ILibHttp {
    this.mainData$.loading$.next(true);
    let s = new LibHttpService(
      this.http,
      LibHttpUrl.apiUrl,
      apiName,
      this.hhe,
      this.mainData$
    );

    s.setHeader('Content-Type', 'application/json; charset=utf-8');
    return s;
  }

  ApiDownload(apiName: string): ILibHttp { 
    this.mainData$.loading$.next(true);
    let s = new LibHttpService(
      this.http,
      LibHttpUrl.apiUrl,
      apiName,
      this.hhe,
      this.mainData$
    );

    s.setHeader('Content-Type', 'application/json');
    s.setHeader('responseType', 'blob' as 'json');
    return s;
  }

}

export class LibHttpService {
  private _header: HttpHeaders;

  constructor(
    private http: HttpClient,
    private apiUrl: string,
    private apiName: string,
    private hhe: ILibHandleHttpError,
    public mainData$: mainData,
  ) {
    this._header = new HttpHeaders();
  }

  HandleError(error: any) {
    return this.hhe.HandleError(error);
  }

  setUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  setApi(apiName: string) {
    this.apiName = apiName;
  }

  deleteHeader(key: any) {
    this._header = this._header.delete(key);
  }

  setHeader(key: any, value: any) {
    if (this._header.has(key)) {
      this._header = this._header.set(key, value);
    } else {
      this._header = this._header.append(key, value);
    }
  }

  get<T>(params?: any): Observable<T> {
    const fullPath = `${this.apiUrl}${this.apiName}`;
    let obj: any = {};
    let httpParams;
    if (params) {
      httpParams = new HttpParams();
      for (let key in params) {
        httpParams = httpParams.append(key, params[key].toString());
      }
      obj.params = httpParams;
    }
    obj.headers = this._header;
    return this.http.get<T>(fullPath, obj).pipe(
      catchError((err) => {
        return this.HandleError(err);
      })
    );
  }

  post<T>(data: any): Observable<T> {
    const fullPath = `${this.apiUrl}${this.apiName}`;
    return this.http.post<T>(fullPath, data, { headers: this._header }).pipe(
      catchError((err) => {
        this.mainData$.loading$.next(false);
        return this.HandleError(err);
      })
    );
  }

  update<T>(data: any): Observable<T> {
    const fullPath = `${this.apiUrl}${this.apiName}`;
    return this.http.put<T>(fullPath, data, { headers: this._header }).pipe(
      catchError(this.HandleError)
    );
  }

  delete<T>(id: any): Observable<T> {
    // if (id) {
    const fullPath = `${this.apiUrl}${this.apiName}/${id}`;
    return this.http.delete<T>(fullPath, { headers: this._header }).pipe(
      catchError(this.HandleError)
    );
    // }
  }

  download<T>(paramUrl: string, paramBody: object, paramOption?: object): Observable<T> {
    let option = { responseType: "blob" as "json" };
    let data = {paramUrl, paramBody, option}
    const fullPath = `${this.apiUrl}${this.apiName}`;
    return this.http.post<T>(fullPath, data, { headers: this._header }).pipe(
      catchError(this.HandleError)
        // catchError((err) => {
        //     if (err instanceof HttpErrorResponse) {
        //         return throwError(this.error(err));
        //     }
        // })
    );
  }
}
