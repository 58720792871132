<header id="header">
  <div class="headerline">
    <div class="headerline1"></div>
    <div class="headerline2"></div>
  </div>
  <main>
    <div class="navToggle" (click)="open = !open">
      <div class="line" [ngClass]="{'line1': open}"></div>
      <div class="line" [ngClass]="{'line2': open}"></div>
      <div class="line" [ngClass]="{'line3': open}"></div>
    </div>
    <a routerLink="/accessibility/sitemap"><img class="logo" src="/assets/images/Logo.png" alt="街口投信LOGO" /></a>
    <div class="nav-wrap">
      <!-- <div class="social-icons">
        <a href="https://www.facebook.com/people/%E8%A1%97%E5%8F%A3%E6%8A%95%E4%BF%A1/100063892302010/" target="_blank">
            <img class="social-icon" src="/assets/images/fb.svg" alt="FB-Link"/>
        </a>
        <a href="https://www.instagram.com/jkoamam/" target="_blank">
            <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link"/>
        </a>
        <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
            <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link"/>
        </a>
    </div> -->
    <div class="social-icons" *ngIf="!searchopen">
      <a *ngFor="let data of homeService.socialIcon" [href]="data.CUrl" target="_blank">
        <img *ngIf="data.CType == 2" class="social-icon" [src]="data.CIcon" [alt]="data.CName" />
        <span class="socialText" *ngIf="data.CType == 1">{{data.CName}}</span>
      </a>
      <!-- <a href="https://www.facebook.com/people/%E8%A1%97%E5%8F%A3%E6%8A%95%E4%BF%A1/100063892302010/" target="_blank">
        <img class="social-icon" src="/assets/images/fb.svg" alt="FB-Link" />
      </a>
      <a href="https://www.instagram.com/jkoamam/" target="_blank">
        <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link" />
      </a>
      <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
        <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link" />
      </a> -->
    </div>
      <nav class="menu">
        <ul>
          <a class="nav-accesskey" role="nav" accesskey="U" title="上方Logo與選單連結區" href="javascript:void();" name="U" (keyup)="bgClose()">:::</a>
          <li class="nav-item dropdown">
            <a title="基金中心連結" class="nav-link" (mouseenter)="getMenuId(1)" href="/accessibility/fund-list"  (keyup)="getMenuId(1)"  data-toggle="dropdown"  role="button"
              aria-haspopup="true" aria-expanded="false">
              基金中心
            </a>
            <div class="dropdown-menu" *ngIf="currentMenu === 1" (mouseleave)="bgClose()">
              <a class="dropdown-item" href="/accessibility/fund-list" title="基金總覽連結">基金總覽</a>
              <a class="dropdown-item" href="/accessibility/fund-performance" title="基金績效連結">基金績效</a>
              <a class="dropdown-item" href="/accessibility/dividend-performance" title="配息專區連結">配息專區</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a title="服務專區連結" class="nav-link" data-toggle="dropdown" href="/accessibility/form-type" (mouseenter)="getMenuId(2)"  (keyup)="getMenuId(2)" role="button"
              aria-haspopup="true" aria-expanded="false">
              服務專區
            </a>
            <div id='Download' class="dropdown-menu" *ngIf="currentMenu === 2" (mouseleave)="bgClose()">
              <a class="dropdown-item" href="/accessibility/form-type" title="表單下載連結">表單下載</a>
              <a class="dropdown-item" href="/accessibility/question" title="常見問題連結">常見問題</a>
              <a class="dropdown-item" href="/accessibility/contact/5"
                title="聯絡我們連結">聯絡我們</a>
              <a class="dropdown-item" href="/accessibility/friendly/6" title="金融友善服務連結">金融友善服務</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a title="網站導覽連結" class="nav-link" data-toggle="dropdown" href="/accessibility/sitemap" (keyup)="bgClose()" role="button" aria-haspopup="true"
              aria-expanded="false">
              網站導覽
            </a>
            <!-- <div id='Download' class="dropdown-menu" *ngIf="currentMenu === 3">
              <a class="dropdown-item" href="~/Home/DownLoad" title="文件下載連結">文件下載</a>
              <a class="dropdown-item" href="~/Home/Question" title="常見問題連結">常見問題</a>
              <a onkeydown="onkeydownFunction('#Download')" class="dropdown-item" href="~/Home/Contact"
                title="聯絡我們連結">聯絡我們</a>
            </div> -->
          </li>
        </ul>
      </nav>

    </div>
  </main>
  <div [ngClass]="{'bk-bg':bgShow}" (click)="bgClose()"></div>
</header>

<div class="top_m" [ngClass]="{'open':open}">
  <div *ngIf="open">
    <p-panel class="p-panel" toggler="header" [header]="'基金中心'" [toggleable]="true" [collapsed]="true">
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg" alt="black-arrow"/>
        <img class="red-arrow" src="/assets/images/top-arrow.svg" alt="top-arrow"/>
      </ng-template>
      <a class="drop-item" href="/accessibility/fund-list" title="基金總覽連結">基金總覽</a>
      <a class="drop-item" href="/accessibility/fund-performance" title="基金績效連結">基金績效</a>
      <a class="drop-item" href="/accessibility/dividend-performance"  title="配息專區連結">配息專區</a>
    </p-panel>
    <p-panel class="p-panel" toggler="header" [header]="'服務專區'" [toggleable]="true" [collapsed]="true">
      <ng-template pTemplate="headericons">
        <img class="black-arrow" src="/assets/images/black-arrow.svg"alt="black-arrow" />
        <img class="red-arrow" src="/assets/images/top-arrow.svg" alt="top-arrow" />
      </ng-template>
      <a class="drop-item" href="/accessibility/form-type" title="表單下載連結">表單下載</a>
      <a class="drop-item" href="/accessibility/question" title="常見問題連結">常見問題</a>
      <a class="drop-item" href="/accessibility/contact/5"  title="聯絡我們連結">聯絡我們</a>
      <a class="drop-item" href="/accessibility/friendly/6"  title="金融友善服務連結">金融友善服務</a>
    </p-panel>
    <div class="p-panel-text" toggler="header" title="網站導覽連結"><a href="/accessibility/sitemap">網站導覽</a></div>
    <!-- <p-panel class="p-panel" toggler="header" [header]="'網站導覽'" [toggleable]="false" [collapsed]="false">
    </p-panel> -->
   <!-- 社群icon -->

   <div class="social-icons-mb">
    <a *ngFor="let data of homeService.socialIcon" [href]="data.CUrl" target="_blank">
      <img *ngIf="data.CType == 2" class="social-icon" [src]="data.CIcon" [alt]="data.CName" />
      <span class="socialText" *ngIf="data.CType == 1">{{data.CName}}</span>
    </a>
    <!-- <a href="https://www.facebook.com/people/%E8%A1%97%E5%8F%A3%E6%8A%95%E4%BF%A1/100063892302010/" target="_blank">
        <img class="social-icon" src="/assets/images/fb.svg" alt="FB-Link"/>
    </a>
    <a href="https://www.instagram.com/jkoamam/" target="_blank">
        <img class="social-icon" src="/assets/images/ig.svg" alt="IG-Link"/>
    </a>
    <a href="https://www.youtube.com/channel/UCFRNOqW3tQ-3EXhk8bEHVYg/" target="_blank">
        <img class="social-icon" src="/assets/images/youtube.svg" alt="youtube-Link"/>
    </a> -->
</div>
  </div>
</div>
