import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpApi } from './service/lib-http.service';
import { LibHttpIntercepterService } from './service/lib-http-intercepter.service';
import { ILibHttpApi, ILibHttp } from './interface/lib-http.interface';
import { ILibHandleHttpError } from './interface/lib-handle-http-error.interface';
import { LibHandleHttpErrorService } from './service/lib-handle-http-error.service';
import { ILibLocalStorage } from './interface/lib-local-storage.interface';
import { LibLocalStorageService } from './service/lib-local-storage.service';

@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LibHttpIntercepterService,
      multi: true
    },
    {
      provide: ILibLocalStorage,
      useClass: LibLocalStorageService
    },
    {
      provide: ILibHttpApi,
      useClass: HttpApi
    },{
      provide: ILibHttp,
      useClass: HttpApi
    },
    {
      provide: ILibHandleHttpError,
      useClass: LibHandleHttpErrorService
    }
  ]
})
export class MyLibraryModule { }
