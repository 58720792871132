import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, Input, OnInit, PLATFORM_ID
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { mainData } from '../../../shared/interface/main-data';
import { HomeService } from '../../../shared/services/home.service';

@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss'],
})
export class BannerCarouselComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Input() name: string = '';
  web: boolean = true;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 6000,
    navText: ['', ''],
    merge: true,
    margin: 20,
    autoHeight: false,
    responsive: {
      //在特定寬度下顯示幾張圖
      0: {
        items: 1,
      },
    },
  };
  slidesStore: Array<any> = [];
  scrWidth = 0;
  isBrowser: boolean;
  constructor(
    public homeService: HomeService,
    public mainData$: mainData,
    @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
  }

  // 圖片輪播時觸發的事件
  getData(e: any) {
    // console.log('$event',e);
  }

  SaveClickCount(id:number) {
    this.homeService.SaveClickCount(id).subscribe((res) => {
      this.mainData$.loading$.next(false);
    });
  }

}
