import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { BaseService } from './shared/services/base.service';
import { HomeService } from './shared/services/home.service';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { EventsService } from 'src/app/shared/services/events.service';
import { Meta, Title } from '@angular/platform-browser';
import { MetaData } from './shared/models/home';
import { isPlatformBrowser } from '@angular/common';
import { computeShrinkWidth } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jkoam';
  scrWidth = 0;
  metaTagData: MetaData = {} as MetaData;
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public baseService: BaseService,
    public homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    public eventsService: EventsService,
    private metaTagService: Meta,
    private titleService: Title,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.isBrowser) {
          let urls = window.location.pathname.split('/');
          if (urls.length >= 2 && urls[1] === 'accessibility') this.baseService.Accessible$.next(true);
          else this.baseService.Accessible$.next(false);
        }
      }
    });
    this.scrWidth = window.innerWidth;
    if (this.scrWidth >= 640) {
      this.baseService.Web$.next(true)
    } else {
      this.baseService.Web$.next(false)
    }
     this.getMetaData();
    this.getSocialIcon();
   // this.privateMonthlyReportEvent();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let scrWidth = window.innerWidth;
    if (scrWidth >= 640) {
      this.baseService.Web$.next(true)
    } else {
      this.baseService.Web$.next(false)
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.isBrowser) {
      let scrollY = window.scrollY;
      if (scrollY > 86) {
        document.getElementById("header")?.classList.add("fixed");
        document.getElementById("go-top")?.classList.add("opened");
        document.getElementById("outlet")?.classList.add("outlet");
      } else {
        document.getElementById("header")?.classList.remove("fixed");
        document.getElementById("go-top")?.classList.remove("opened");
        document.getElementById("outlet")?.classList.remove("outlet");
      }
    }
  }

  getSocialIcon() {
    this.homeService.GetHeaderCommunity().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.homeService.socialIcon = res.Entries;
      }
    });
  }

  getMetaData() {
    this.homeService.GetMetaData().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.metaTagData = res.Entries
        // if (this.metaTagData.CTitle) this.titleService.setTitle(this.metaTagData.CTitle)
        // if (this.metaTagData.CDescription) this.metaTagService.updateTag({ name: 'description', content: this.metaTagData.CDescription });
        // if (this.metaTagData.CKeyWord) this.metaTagService.updateTag({ name: 'keyword', content: this.metaTagData.CKeyWord });
        if (this.isBrowser) this.loadHeadGTM();
        if (this.isBrowser) this.loadBodyGTM();
      }
    });
  }

  loadHeadGTM() {
    const regex = /<script>([\s\S]+?)<\/script>/g;
    const matches = this.metaTagData.CGTMCode.match(regex);
    if (matches && matches.length >= 1) {
      matches.map(val => {
        const node = document.createElement('script');
        node.innerHTML = val.replace('<script>', '').replace('</script>', '').trim();
        node.type = 'text/javascript';
        node.async = false;
        document.getElementsByTagName('head')[0].appendChild(node);
      })
    }
  }

  loadBodyGTM() {
    let data = ''
    const regex = /<noscript>([\s\S]+?)<\/noscript>/i;
    const matches = this.metaTagData.CGTMCode.match(regex);
    if (matches && matches.length > 1) {
      data = matches[1];
    }
    const node = document.createElement('noscript');
    node.innerHTML = data;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  goTop() {
    if (this.isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  privateMonthlyReportEvent() {
    document.addEventListener('click', function (event) {
        let el = event.target as HTMLElement;
        let anchor = el.closest('a'); // 檢查是否在a標籤內部
       // console.log(anchor)
        if (anchor) {
            let url = anchor.getAttribute('href');
          //  console.log(url);
            if (url) {
                let currentDomain = window.location.hostname;
                let link = document.createElement('a');
                link.href = url;
                let targetDomain = link.hostname;
                if (targetDomain === 'w2.jkoam.com' ||targetDomain === 'ec.jkoam.com') {
                  return;
              }
                if (currentDomain !== targetDomain && !url.startsWith('javascript:void(0)') && !url.startsWith('javascript:void();')) {
                  if (confirm('您即將離開街口投信官網，前往其他業者經營的網站。提醒您，其他業者的網站隱私權保護及資訊安全事項皆不屬於街口投信可控制與負責範圍。')) {
                      window.open(url);
                  }
                  event.preventDefault();
              }
            }
        }
    });
}

  ngAfterViewInit(): void {
    this.privateMonthlyReportEvent();
  }
}
