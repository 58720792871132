import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { Articledata, ResponseArticle } from '../../models/article';
import { HomeService } from '../../services/home.service';
import { BaseService } from '../../services/base.service';
import { AritcleService } from '../../services/article.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { mainData } from '../../../shared/interface/main-data';
interface PageEvent {
   first: number | undefined;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-announcement-info',
  templateUrl: './announcement-info.component.html',
  styleUrls: ['./announcement-info.component.scss']
})
export class AnnouncementInfoComponent {
  @Input() data: any[] = [];
  TotalItems = 0;
  url = '';
  newsres = new ResponseArticle;
  newslist: Articledata = {} as Articledata;
  @Input() FunctionID: number = 0;
  first: number = 1;
  rows: number = 6;

  constructor(
    private route: ActivatedRoute,
    public homeService: HomeService,
    public baseService: BaseService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {
  }

  ngOnInit(): void {
    this.newsres.PageSize= 6;
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'news') {
        this.url = '/point/world-detail'
      } else if (currentPath === 'weekly-report') {
        this.url = '/point/weekly-report-detail'
      }
      else if (currentPath === 'quarterly-report') {
        this.url = '/point/quarterly-report-detail'
      }
      else if (currentPath === 'esg') {
        this.url='/about-us/esg-detail'
      }
      else if (currentPath === 'classroom') {
        this.url='/etf/classroom-detail'
      }
      else if (currentPath === 'video') {
        this.url='/etf/video-detail'
      }
      else if (currentPath === 'intro') {
        this.url='/etf/intro-detail'
      }
    });
    this.Getnews()
  }


  Getnews() {
    this.newsres.FunctionID= this.FunctionID;
    this.aritcleService.GetArticleByUnit(this.newsres).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.newslist = res.Entries;
        this.TotalItems= res.TotalItems;
      }
      this.mainData$.loading$.next(false);
    });
  }

  more() {
    this.newsres.PageSize=this.newsres.PageSize + 6;
    this.Getnews()
  }

  getLink(item: any): string {
    switch (item.CLinkType) {
      case 1:
        return item.CLinkUrl;
      case 2:
        return item.CLinkFile;
      case 3:
        return `${this.url}/news${item.CId}`;
      case null:
        return `${this.url}/news${item.CId}`;
      default:
        return `${this.url}/news${item.CId}`;
    }
  }

  getTarget(data: any): string {
    switch (data.CLinkType) {
      case 1:
      case 3:
      case null:
        return data.CAnother == 1 ? '_blank' : '_self';
      case 2:
        return '_blank';
      default:
        return '_self';
    }
  }

  paginate(event: any) {
    this.newsres.PageIndex = event.page + 1;
     this.first = event.first;
     this.Getnews();
  }

}
