import { UserService } from './../../services/user.service';
import { ActivityService } from './../../services/activity.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ResponseContact, Responsefeeback } from '../../models/activity';
import { ImgToken } from '../../models/user';
import { DialogData } from '../confirm-dialog/confirm-dialog.component';
import { ArticledataID, ResponseArticleID } from '../../models/article';
import { AritcleService } from '../../services/article.service';
import { mainData } from '../../../shared/interface/main-data';
import axios from 'axios';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {
  selectedCities = ''
  request = {} as ResponseContact;
  res: Responsefeeback = {} as Responsefeeback;
  resimg: ImgToken = {} as ImgToken;
  Token = '';
  visible = false;
  dialogData: DialogData = {} as DialogData;
  checkbox = false;
  disableagreeChecked = true;
  requestNew = new ResponseArticleID;
  list: ArticledataID = {} as ArticledataID;
  errorTest: string[] = [];
  errorFormat: string[] = [];
  CIP = '';
  report = false;
  add = false;
  visibleType = 0;
  constructor(
    private route: ActivatedRoute,
    public activityService: ActivityService,
    public userService: UserService,
    public aritcleService: AritcleService,
    public mainData$: mainData
  ) {

  }

  ngOnInit(): void {
    this.request.CExReplyTime = [];
    this.route.url.subscribe(urlSegments => {
      const currentPath = urlSegments[0].path;
      if (currentPath === 'appeals') {
        this.selectedCities = '檢舉事項'
        this.report = true;
      } else if (currentPath === 'contact') {
        this.selectedCities = '問題或建議'
      }
    });
    this.GetCaptchaImg()
    this.requestNew.FunctionID = 7343,
      this.requestNew.ID = 4,
      this.GetArticleByID();
  }

  ContantUs() {
    this.errorTest = [];
    this.errorFormat = [];
    this.dialogData.message = ''
    if (!this.request.CName || !/^\s*\S/.test(this.request.CName)) {
      this.errorTest.push('姓名');
    }
    if (!this.request.CEmail || !/^\s*\S/.test(this.request.CEmail)) {
      this.errorTest.push('E-mail');
    }
    if (this.request.CEmail && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.request.CEmail)) {
      this.errorFormat.push('E-mail');
    }
    if (this.request.CPhone && !/^09\d{8}$/.test(this.request.CPhone)) {
      this.errorFormat.push('行動電話');
    }
    if (this.request.CTel && !/^(?!09|00|01)\d{2,4}\d{7,8}$/.test(this.request.CTel)) {
      this.errorFormat.push('日間電話');
    }
    if (!this.request.CMsgContent) {
      if (this.selectedCities === '問題或建議') {
        this.errorTest.push('問題與建議');
      }
      else {
        this.errorTest.push('問題與檢舉事項');
      }
    }
    if (!this.request.Code) {
      this.errorTest.push('驗證碼');
    }

    if (!this.checkbox) {
      this.errorTest.push('個人資料蒐集、處理及利用告知事項');
    }

    if (this.errorTest.length || this.errorFormat.length) {
      this.visible = true;
      this.visibleType = 0;
      this.dialogData.title = '提醒'
      if (this.errorTest.length) this.dialogData.message = this.errorTest.toString() + '為必填欄位。<br>'
      if (this.errorFormat.length) this.dialogData.message = this.dialogData.message + this.errorFormat.toString() + '欄位格式錯誤。'
    } else this.submit();
  }


  async submit() {
    this.request.CodeToken = this.Token;
    if (this.selectedCities === '問題或建議') {
      if (!this.CIP) await this.getIpInfo();
      else this.request.CIP = this.CIP;
      this.activityService.CreateContactUs(this.request).subscribe((res) => {
        if (res.StatusCode == EnumStatusCode.Success) {
          this.res.Message = res.Message;
          this.add = true;
          this.OpenDialog(0);
          this.reset();
        } else if (res.StatusCode == EnumStatusCode.Fail) {
          this.res.Message = res.Message;
          this.OpenDialog(0);
        }
        this.mainData$.loading$.next(false);
      });
    } else {
      this.activityService.CreateReport(this.request).subscribe((res) => {
        if (res.StatusCode == EnumStatusCode.Success) {
          this.res.Message = res.Message;
          this.OpenDialog(0);
          this.reset();
        } else if (res.StatusCode == EnumStatusCode.Fail) {
          this.res.Message = res.Message;
          this.OpenDialog(0);
        }
        this.mainData$.loading$.next(false);
      });
    }
  }

  reset() {
    this.request = {} as ResponseContact;
    this.checkbox = false;
    this.GetCaptchaImg();
  }

  GetCaptchaImg() {
    this.userService.GetCaptchaImg().subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.resimg = res.Entries;
        this.Token = this.resimg.Token;
      }
      this.mainData$.loading$.next(false);
    });
  }

  refresh() {
    this.GetCaptchaImg();
  }

  OpenDialogremark() {
    this.visible = true;
    this.visibleType = 0;
    this.dialogData.title = '提醒'
  }

  OpenDialog(type: any) {
    this.visibleType = type;
    this.visible = true;
    if (this.add) {
      this.dialogData.title = '新增成功'
      this.add = false;
    }
    else { this.dialogData.title = '提醒' }
    this.dialogData.message = this.res?.Message
  }

  policyDialog(type: any) {
    if (type == 1) {
      this.visible = true;
      this.visibleType = type;
      this.dialogData.title = '個人資料蒐集、處理及利用告知事項'
      this.dialogData.message = this.list.CContent
    }
  }

  visibleChange(e: any) {
    this.visible = false;
    if(this.visibleType == 1){
        this.checkbox = true;
        this.disableagreeChecked = false;
    }
  }

  //個資單篇文章
  GetArticleByID() {
    this.aritcleService.GetArticleByID(this.requestNew).subscribe((res) => {
      if (res.StatusCode == EnumStatusCode.Success) {
        this.list = res.Entries;
      }
    });
  }

  //取得IP
  async getIpInfo() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      this.CIP = response.data.ip;
      this.request.CIP = response.data.ip;
    } catch (error) {
      console.error(error);
    }
  }
}
