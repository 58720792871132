<div class="wrapper">
  <main>
    <div class="form-box" [ngClass]="{'form-box-report': report}">
      <div class="form-left" [ngClass]="{'form-box-block': report}">
        <div *ngIf="!report">
          <h2>聯絡表單</h2>
          <div class="text">歡迎使用聯絡表單填寫您的諮詢事項、寶貴意見及聯絡方式。查詢【帳戶交易】、【投資庫存】等相關事宜，請於服務時間撥打客服專線，將有專人為您服務。</div>
          <div class="phone">
            <h2>客服專線｜0800-818-899</h2>
            臨櫃服務請先洽詢客服專線預約
          </div>
        </div>
        <div *ngIf="report">
          <h2>本公司為促進健全經營，建立有效之檢舉管道及處理程序，如發現本公司人員有犯罪、舞弊或違反法令之虞時，得向本公司提出檢舉。</h2>
          <div class="report-text">檢舉以書面方式為之者，除實體書面寄送外，亦可使用線上檢舉信箱之方式。<br>
            檢舉以口頭方式為之者，包括電話或親至陳述等方式，本公司受理單位將協助作成紀錄，載明陳述事項、真實姓名及聯絡方式，並由檢舉人簽名或蓋章確認後，據以辦理。<br>
            檢舉內容須載明檢舉人之真實姓名及聯絡方式，並提供足夠資訊以利查證，包括被檢舉人姓名、服務單位及職稱或其他足資識別被檢舉人身分特徵之資料、可供調查之具體事實及合理懷疑事證。<br> 本公司檢舉管道如下：<br>
            檢舉專線：(02)2740-1446 <br>檢舉地址：106台北市大安區市民大道三段206號9樓 檢舉信箱收</div>
        </div>
      </div>
      <form class="form-right" [ngClass]="{'form-box-block': report}">
        <h2 style="margin-bottom: 20px;" *ngIf="report">線上檢舉信箱</h2>
        <div class="input-box">
          <div class="title">姓名<span class="require">＊必填</span></div>
          <input placeholder="請輸入姓名" name="CName" [(ngModel)]="request.CName">
        </div>
        <div class="input-box">
          <div class="title">E-mail<span class="require">＊必填</span></div>
          <input placeholder="請輸入E-mail" name="CEmail" [(ngModel)]="request.CEmail">
        </div>
        <div class="input-box">
          <div class="title">聯絡電話</div>
          <div class="input50box">
            <input class="input50" placeholder="行動電話(範例：0912345678)" name="CPhone" [(ngModel)]="request.CPhone">
            <input class="input50 input50mb" placeholder="日間電話(範例：0222345678)" name="CTel" [(ngModel)]="request.CTel">
          </div>
        </div>
        <div class="input-box">
          <div class="title">聯絡時段</div>
          <!-- <p-checkbox  [binary]="true" inputId="binary">早上9-12</p-checkbox>
          <p-checkbox  [binary]="true" inputId="binary">下午13-17</p-checkbox> -->
          <div class="check">
            <div class="flex">
              <p-checkbox name="group1" value="上午" [(ngModel)]="request.CExReplyTime" inputId="AM"></p-checkbox>
              <label for="AM">早上09:00-12:00</label>
            </div>
            <div class="flex">
              <p-checkbox name="group1" value="下午" [(ngModel)]="request.CExReplyTime" inputId="PM"></p-checkbox>
              <label for="PM">下午14:00-17:00</label>
            </div>
          </div>
        </div>
        <div class="input-box">
          <div class="title">{{selectedCities}}<span class="require">＊必填</span></div>
          <textarea rows="10" cols="50" placeholder="請輸入您的問題或建議" name="CMsgContent"
            [(ngModel)]="request.CMsgContent"></textarea>
        </div>
        <div class="input-box">
          <div class="title">驗證碼<span class="require">＊必填</span></div>
          <div class="refreshbox">
            <input class="refreshinput" placeholder="請輸入圖形驗證碼" name="Code" [(ngModel)]="request.Code">
            <!-- <img src="/assets/images/code.svg"> -->
            <img [src]="resimg.Base64" *ngIf="resimg.Base64">
            <img class="refresh" src="/assets/images/refresh.svg" alt="refresh" (click)="refresh()">
          </div>
        </div>
        <!-- <div class="input-box">
          <div class="check web">
            <p-checkbox name="group2" value="agree" inputId="agree" [(ngModel)]="checkbox" [binary]="true" [disabled]="disableagreeChecked"></p-checkbox>
            <label for="agree">本人已知悉、同意街口投信「<a (click)="policyDialog(1)">個人資料蒐集、處理及利用告知事項</a>」</label>
          </div>
          <div class="check check2 mb">
            <div>
              <p-checkbox name="group2" value="agree" inputId="agree" [(ngModel)]="checkbox"
                [binary]="true"></p-checkbox>
            </div>
            <label for="agree">本人已知悉、同意街口投信<br>「<a (click)="policyDialog(1)">個人資料蒐集、處理及利用告知事項</a>」</label>
          </div>
        </div> -->
        <div class="form-item checkbox-wrap" (click)="policyDialog(1)">
          <p-checkbox class="checkbox" name="agreeChecked" [(ngModel)]="checkbox" [binary]="true" inputId="binary"
            [disabled]="disableagreeChecked"></p-checkbox>
          <span>本人已知悉同意街口投信<span class="agrees">「<span class="agree-info">個人資料蒐集、處理及利用告知事項</span>」</span></span>
        </div>
        <div class="confirm-btn-box">
          <button type="submit" class="confirm-btn" (click)="ContantUs()">確認送出</button>
        </div>
      </form>
    </div>
  </main>
</div>

<app-message-dialog *ngIf="visible" [visible]="visible" [data]="dialogData" [privateType]="visibleType"
  (visibleChange)="visibleChange($event)"></app-message-dialog>
