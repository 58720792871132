<div class="wrapper">
  <main>
    <div>
      <div class="listbox">
        <ng-container *ngFor="let item of newslist.Data">
          <div class="list">
            <a [href]="getLink(item)" [attr.target]="getTarget(item)">
              <img *ngIf="item.CPhotoFileName" src="{{item.CPhotoFileName}}" alt="{{item.CPhotoName}}">
              <div class="list-text">
                <div class="date">{{item.CShowStartDt | date: 'yyyy/MM/dd'}}</div>
                <div class="title">{{item.CName}}</div>
                <div class="listcontent">{{item.CFileDescription}}</div>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
      <!-- <ng-container>
        <button *ngIf="TotalItems > (newslist.Data && newslist.Data.length)" class="arrow-btn" (click)="more()">
          顯示更多
          <div class="top-arrow"></div>
        </button>
        <p class="no-data" *ngIf="(newslist.Data && newslist.Data.length) >= TotalItems">無更多資料</p>
      </ng-container> -->
      <ng-container>
        <p-paginator (onPageChange)="paginate($event)"
        [first]="first"
        [rows]="6"
        [totalRecords]="TotalItems" />
      </ng-container>
    </div>
  </main>
</div>
