<!-- <ng-container *ngIf="!eventsService.isShow.header">
  <section class="section-18">
    <header class="header" [ngStyle]="{ 'background-color': '#3e454d' }">
      <div class="container">
        <div class="header-wrap">
          <div>
            <a
              href="./"
              title="鉅亨買基金 – 全台最大民營線上基金交易平台"
              style="display: inline-block"
            >
              <img
                class="header__logo"
                src="./assets/images/logo/logo-2.png"
                alt=""
              />
            </a>
          </div>

          <div
            class="hamburger"
            [ngClass]="{ active: isNav }"
            (click)="hbClick()"
          >
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>

          <nav class="nav" [ngClass]="{ active: isNav }">
            <ul>
              <ng-container *ngFor="let item of event.Anchors">
                <li
                  appMenuHover
                  [color]="'#fff'"
                  [backgroundColor]="'#3e454d'"
                  [hoverColor]="'#fff'"
                  [hoverBackgroundColor]="'#565f6a'"
                  (click)="tabClick(item.SubId)"
                >
                  <div class="container">
                    <p>
                      {{ item.Name }}
                    </p>
                  </div>
                </li>
              </ng-container>
            </ul>
          </nav>
        </div>
      </div>
    </header>

    <div class="header-h"></div>
  </section>
</ng-container> -->

<!-- <ng-container *ngIf="eventsService.isShow.header">
  <section class="section-19">
    <nav class="nav" [ngStyle]="{ 'background-color': '#3e454d' }">
      <div class="container">
        <div class="nav-wrap">
          <ul>
            <ng-container *ngFor="let item of event.Anchors; let index = index">
              <li
                appMenuHover
                [color]="'#fff'"
                [backgroundColor]="'#3e454d'"
                [hoverColor]="'#fff'"
                [hoverBackgroundColor]="'#565f6a'"
                (click)="tabClick(item.SubId)"
              >
                {{ item.Name }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</ng-container> -->

<ng-container *ngIf="!eventsService.isShow.header">
  <section>
    <header id="header">
      <div class="headerline">
        <div class="headerline1"></div>
        <div class="headerline2"></div>
      </div>
      <main>
        <div class="navToggle" (click)="open = !open">
          <div class="line" [ngClass]="{'line1': open}"></div>
          <div class="line" [ngClass]="{'line2': open}"></div>
          <div class="line" [ngClass]="{'line3': open}"></div>
        </div>
        <a routerLink="/" class="web"><img class="logo" src="/assets/images/Logo.png" alt="街口投信LOGO" /></a>
        <a routerLink="/" class="mb"><img class="logo" src="/assets/images/Logo.png" alt="街口投信LOGO" /></a>

        <div class="nav-wrap">
          <nav class="menu">
            <ul>
              <li *ngFor="let item of event.Anchors; let index = index" appMenuHover [color]="'#212123'"
                [backgroundColor]="'#fff'" [hoverColor]="'#212123'" (click)="tabClick(item.SubId)">
                {{ item.Name }}
              </li>
            </ul>
          </nav>
        </div>
      </main>

    </header>
    <div class="top_m" [ngClass]="{'open':open}">
      <div *ngIf="open">
        <ul>
          <li *ngFor="let item of event.Anchors; let index = index" appMenuHover [color]="'#212123'"
            [backgroundColor]="'#fff'" [hoverColor]="'#212123'" (click)="tabClick(item.SubId);open = !open">
            {{ item.Name }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</ng-container>

<section class="section-01" *ngIf="event.CKvimage || event.CKvimageMobile">
  <img class="img" [src]="isMobile ? event.CKvimageMobile : event.CKvimage"
    [alt]="isMobile ? event.CMobileSummary : event.CWebSummary" />
</section>
<div class="container">
  <ng-container *ngIf="event?.CName">
    <div class="title">{{event.CName}}</div>
  </ng-container>
  <ng-container *ngFor="let item of event.SubItems; let index = index">
    <!-- 文編輯器 -->
    <section [id]="'anchor-' + item.CId" class="section-02" *ngIf="item.CType == '3'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <div class="text" [innerHTML]="item.JsonColumns?.Content | html"></div>
    </section>
    <!-- 圖(單一全幅) -->
    <section [id]="'anchor-' + item.CId" class="section-02" *ngIf="item.CType == '4'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <img class="img" [src]="item.JsonColumns?.PhotoFile1" [alt]="item.JsonColumns?.ImgSummary1" />
    </section>
    <!-- 圖上+文編輯器下 -->
    <section [id]="'anchor-' + item.CId" class="section-03" *ngIf="item.CType == '5'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <img class="img" [src]="item.JsonColumns?.PhotoFile1" [alt]="item.JsonColumns?.ImgSummary1" />
      <div class="text" [innerHTML]="item.JsonColumns?.Description1 | html"></div>
    </section>
    <!-- 圖上+文編輯器下 -->
    <section [id]="'anchor-' + item.CId" class="section-03" *ngIf="item.CType == '6' || item.CType == '7'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <img class="img" [src]="item.JsonColumns?.PhotoFile1" [alt]="item.JsonColumns?.ImgSummary1" />
      <div class="text" [innerHTML]="item.JsonColumns?.Content | html"></div>
    </section>
    <!-- 圖(左邊+文編輯器左下)+圖(中間+文編輯器中下)+圖(右邊+文編輯器右下) (預設顯示3塊) -->
    <section [id]="'anchor-' + item.CId" class="section-05 interested" *ngIf="item.CType == '8' || item.CType == '9'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <ul class="list" *ngIf="item.ShowData.length">
        <owl-carousel-o [options]="item.CType == '8' ? customOptions : customOptions2" #owlCar1>
          <ng-template carouselSlide *ngFor="let slide of item.ShowData">
            <div class="card">
              <img class="img" [src]="slide.PhotoFile" [alt]="slide.ImgSummary" />
              <div class="content" [ngClass]="{'slide-content':item.CType == '8','slide-content2': item.CType == '9'}"
                [innerHTML]="slide.Description | html"></div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- <swiper
          [breakpoints]="{
            '0': { slidesPerView: 1, slidesPerGroup: 1 },
            '768': { slidesPerView: 2, slidesPerGroup: 2 },
            '992': { slidesPerView: 3, slidesPerGroup: 3 }
          }"
          [pagination]="{ clickable: true }"
          class="interested__swiper"
        >
          <ng-template swiperSlide *ngFor="let item of item.ShowData">
            <li class="item">
              <div class="card">
                <img class="img" [src]="item.PhotoFile" [alt]="item.ImgSummary" />
                <div
                  class="content"
                  [innerHTML]="item.Description | html"
                ></div>
              </div>
            </li>
          </ng-template>
        </swiper> -->
      </ul>
    </section>
    <!-- 圖(左邊+文編輯器左下)+圖(右邊+文編輯器右下) -->
    <section [id]="'anchor-' + item.CId" class="section-07 interested" *ngIf="item.CType == '10'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <ul class="list" *ngIf="item.ShowData.length">
        <owl-carousel-o [options]="customOptions3" #owlCar1>
          <ng-template carouselSlide *ngFor="let slide of item.ShowData">
            <div class="card">
              <img class="img" [src]="slide.PhotoFile" [alt]="slide.ImgSummary" />
              <div class="content" [ngClass]="{'slide-content':item.CType == '8','slide-content2': item.CType == '9'}"
                [innerHTML]="slide.Description | html"></div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- <swiper
          [breakpoints]="{
            '0': { slidesPerView: 1, slidesPerGroup: 1 },
            '992': { slidesPerView: 2, slidesPerGroup: 2 }
          }"
          [pagination]="{ clickable: true }"
          class="interested__swiper"
        >
          <ng-template swiperSlide *ngFor="let item of item.ShowData">
            <li class="item">
              <div class="card">
                <img class="img" [src]="item.PhotoFile" [alt]="item.ImgSummary" />

                <div
                  class="content"
                  [innerHTML]="item.Description | safe : 'html'"
                ></div>
              </div>
            </li>
          </ng-template>
        </swiper> -->
      </ul>
    </section>

    <!-- 影片(單一全幅) -->
    <section [id]="'anchor-' + item.CId" class="section-08" *ngIf="item.CType == '11'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <div class="video" *ngIf="item.YoutubeUrl">
        <iframe width="560" height="315" [src]="item.YoutubeUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>
    <!-- 影片(左邊+文編輯器左下)+影片(右邊+文編輯器右下) -->
    <section [id]="'anchor-' + item.CId" class="section-09 interested" *ngIf="item.CType == '12'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <ul class="list" *ngIf="item.ShowData.length">
        <owl-carousel-o [options]="customOptions3" #owlCar1>
          <ng-template carouselSlide *ngFor="let slide of item.ShowData">
            <li class="item">
              <div class="card">
                <div class="video" *ngIf="slide.YoutubeUrl">
                  <iframe width="560" height="315" [src]="slide.YoutubeUrl" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
                <div class="content slide-content3" [innerHTML]="slide.Description | html"></div>
              </div>
            </li>
          </ng-template>
        </owl-carousel-o>
        <!-- <swiper
          [breakpoints]="{
            '0': { slidesPerView: 1, slidesPerGroup: 1 },
            '992': { slidesPerView: 2, slidesPerGroup: 2 }
          }"
          [pagination]="{ clickable: true }"
          class="interested__swiper"
        >
          <ng-template swiperSlide *ngFor="let item of item.ShowData">
            <li class="item">
              <div class="card">
                <div class="video" *ngIf="item.YoutubeUrl">
                  <iframe
                    width="560"
                    height="315"
                    [src]="item.YoutubeUrl"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>

                <div
                  class="content"
                  [innerHTML]="item.Description | safe : 'html'"
                ></div>
              </div>
            </li>
          </ng-template>
        </swiper> -->
      </ul>
    </section>
    <!-- 影片(左邊+文編輯器左下)+文編輯器右 -->
    <section [id]="'anchor-' + item.CId" class="section-10" *ngIf="item.CType == '13'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <ul class="list">
        <li class="item">
          <div class="left">
            <div class="card">
              <div class="video" *ngIf="item.YoutubeUrl">
                <iframe width="560" height="315" [src]="item.YoutubeUrl" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>

              <div class="content" [innerHTML]="item.JsonColumns?.Description1 | html"></div>
            </div>
          </div>

          <div class="right">
            <div class="content" [innerHTML]="item.JsonColumns?.Content | html"></div>
          </div>
        </li>
      </ul>
    </section>
    <!-- 影片(右邊+文編輯器右下)+文編輯器左 -->
    <section [id]="'anchor-' + item.CId" class="section-10" *ngIf="item.CType == '14'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <ul class="list">
        <li class="item">
          <div class="left">
            <div class="content" [innerHTML]="item.JsonColumns?.Content | html"></div>
          </div>
          <div class="right">
            <div class="card">
              <div class="video" *ngIf="item.YoutubeUrl">
                <iframe width="560" height="315" [src]="item.YoutubeUrl" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>

              <div class="content" [innerHTML]="item.JsonColumns?.Description1 | html"></div>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <!-- 影片上+文編輯器下 -->
    <section [id]="'anchor-' + item.CId" class="section-11" *ngIf="item.CType == '15'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <div class="video" *ngIf="item.YoutubeUrl">
        <iframe width="560" height="315" [src]="item.YoutubeUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>

      <div class="content" [innerHTML]="item.JsonColumns?.Description1 | html"></div>
    </section>

    <!-- 基金績效 -->
    <section [id]="'anchor-' + item.CId" class="section-13" *ngIf="item.CType == '17'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <!-- 桌機 -->
      <div class="mob-hidden">
        <table>
          <tr>
            <th style="width: 200%;">基金名稱</th>
            <th>近3個月</th>
            <th>近6個月</th>
            <th>近1年</th>
            <th>近2年</th>
            <th>近3年</th>
            <th>今年以來</th>
          </tr>
          <tbody>
            <tr *ngFor="let fund of item.fundPerformance">
              <th style="width: 200%;">
                <p style="text-align: start;">
                  {{ fund.CFullName }}<span class="warning-text">{{fund.CFundWarning}}</span>
                </p>
              </th>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoi3MonthR >= 0,
                    'fund-drop': fund.CRoi3MonthR < 0
                  }">
                  {{ fund.CRoi3MonthRStr }}
                </p>
              </td>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoi6MonthR >= 0,
                    'fund-drop': fund.CRoi6MonthR < 0
                  }">
                  {{ fund.CRoi6MonthRStr }}
                </p>
              </td>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoi1YearR >= 0,
                    'fund-drop': fund.CRoi1YearR < 0
                  }">
                  {{ fund.CRoi1YearRStr }}
                </p>
              </td>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoi2YearR >= 0,
                    'fund-drop': fund.CRoi2YearR < 0
                  }">
                  {{ fund.CRoi2YearRStr }}
                </p>
              </td>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoi3YearR >= 0,
                    'fund-drop': fund.CRoi3YearR < 0
                  }">
                  {{ fund.CRoi3YearRStr }}
                </p>
              </td>
              <td>
                <p [ngClass]="{
                    'fund-raise': fund.CRoiYearStartR >= 0,
                    'fund-drop': fund.CRoiYearStartR < 0
                  }">
                  {{ fund.CRoiYearStartRStr }}
                </p>
              </td>
            </tr>
          </tbody>

        </table>
      </div>

      <!-- 手機 -->
      <div class="pc-hidden fund-list">
        <p-panel class="p-panel" [collapsed]="false" [toggleable]="true"
          *ngFor="let data of item.fundPerformance;let i = index">
          <ng-template pTemplate="header">
            <div>
              {{data.CFullName}}
              <span class="warning-text">{{data.CFundWarning}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="headericons">
            <img class="black-arrow" src="/assets/images/black-arrow.svg" />
          </ng-template>
          <div class="panel-item">
            <div class="name">三個月</div>
            <div class="desc">{{data.CRoi3MonthRStr}}</div>
          </div>
          <div class="panel-item">
            <div class="name">六個月</div>
            <div class="desc">{{data.CRoi6MonthRStr}}</div>
          </div>
          <div class="panel-item">
            <div class="name">一年</div>
            <div class="desc">{{data.CRoi1YearRStr}}</div>
          </div>
          <div class="panel-item">
            <div class="name">兩年</div>
            <div class="desc">{{data.CRoi2YearRStr}}</div>
          </div>
          <div class="panel-item">
            <div class="name">三年</div>
            <div class="desc">{{data.CRoi3YearRStr}}</div>
          </div>
          <div class="panel-item">
            <div class="name">今年以來</div>
            <div class="desc">{{data.CRoiYearStartRStr}}</div>
          </div>
        </p-panel>

      </div>
    </section>

    <section [id]="'anchor-' + item.CId" class="section-14" *ngIf="item.CType == '18'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>
      <div class="detail-table-wrap fund-info" *ngIf="item.fundInfo?.BasicInfo &&  !item.fundInfo?.BasicInfo.IsETF">
        <div class="detail-table detail-table2">
          <div class="item">
            <div class="label">淨值日期</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundNavDate}}</div>
          </div>
          <div class="item" *ngIf="isMobile">
            <div class="label">風險屬性</div>
            <div class="desc">
              <div class="dots" *ngIf="item.fundInfo?.BasicInfo.FundRiskLevelValue">
                <span *ngFor="let dot of dots;let i = index" class="risk-level-dots"
                  [ngClass]="{'risk-level-dots-red': i < item.fundInfo?.BasicInfo.FundRiskLevelValue}"></span>
              </div>
              {{item.fundInfo?.BasicInfo.FundRiskLevelStr}}
            </div>
          </div>

          <div class="item">
            <div class="label">成立日期</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.SetUpDt}}</div>
          </div>

          <div class="item" *ngIf="isMobile">
            <div class="label">基金類型</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundTypeStr}}</div>
          </div>

          <div class="item">
            <div class="label">配息頻率</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundDividendStr}}</div>
          </div>
          <div class="item" *ngIf="isMobile">
            <div class="label">經理人</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.Manager}}</div>
          </div>

          <div class="item" *ngIf="isMobile">
            <div class="label">經理費</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.ManagerFee}}</div>
          </div>

          <div class="item">
            <div class="label">保管費</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.CustodyFee}}</div>
          </div>

          <div class="item" *ngIf="isMobile">
            <div class="label">投資區域</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundAreaStr}}</div>
          </div>
          <div class="item">
            <div class="label">投資標的</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.InvestmentTargets}}</div>
          </div>
        </div>
        <div class="detail-table" *ngIf="!isMobile">
          <div class="item">
            <div class="label">風險屬性</div>
            <div class="desc">
              <div class="dots" *ngIf="item.fundInfo?.BasicInfo.FundRiskLevelValue">
                <span *ngFor="let dot of dots;let i = index" class="risk-level-dots"
                  [ngClass]="{'risk-level-dots-red': i < item.fundInfo?.BasicInfo.FundRiskLevelValue}"></span>
              </div>
              {{item.fundInfo?.BasicInfo.FundRiskLevelStr}}
            </div>
          </div>
          <div class="item">
            <div class="label">基金類型</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundTypeStr}}</div>
          </div>
          <div class="item">
            <div class="label">經理人</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.Manager}}</div>
          </div>
          <div class="item">
            <div class="label">經理費</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.ManagerFee}}</div>
          </div>
          <div class="item">
            <div class="label">投資區域</div>
            <div class="desc">{{item.fundInfo?.BasicInfo.FundAreaStr}}</div>
          </div>
        </div>
      </div>

      <div class="detail-table-wrap etf-info" *ngIf="item.fundInfo?.BasicInfo &&  item.fundInfo?.BasicInfo.IsETF">
        <div class="detail-table">
          <div class="items">
            <div class="item">
              <div class="label">淨值日期</div>
              <div class="desc">{{item.fundInfo?.BasicInfo.FundNavDate}}</div>
            </div>
            <div class="item">
              <div class="label">前一營業日淨值</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.PreviousNav}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">成立日期</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.SetUpDt}}
              </div>
            </div>
            <div class="item">
              <div class="label">上市日期</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.ListingDT}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">股票代碼</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.StockNo}}
              </div>
            </div>
            <div class="item">
              <div class="label">基金類型</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.FundTypeStr}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">標的指數</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.StockIndex}}
              </div>
            </div>
            <div class="item">
              <div class="label">交易單位</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.TradingUnit}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">計價幣別</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.FundCurrencyStr}}
              </div>
            </div>
            <div class="item">
              <div class="label">風險等級</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.FundRiskLevelStr}}
              </div>
            </div>
          </div>

          <div class="items">
            <div class="item">
              <div class="label">經理人</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.Manager}}
              </div>
            </div>
            <div class="item">
              <div class="label">經理費</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.ManagerFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">保管費</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.CustodyFee}}
              </div>
            </div>
            <div class="item">
              <div class="label">指數授權費</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.IndexLicensingFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">配息頻率</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.FundDividendStr}}
              </div>
            </div>
            <div class="item">
              <div class="label">漲跌幅度</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.ChangeRatio}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">升降單位</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.ElevationUnit}}
              </div>
            </div>
            <div class="item">
              <div class="label">信用交易</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.MarginTrading}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">證券交易稅(‰)</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.SecuritiesTransactionTax}}
              </div>
            </div>
            <div class="item">
              <div class="label">次級市場交易手續費<br>(‰)</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.SecMarketTranFee}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">初級市場申購、買回方式</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.PMSubRedMethod}}
              </div>
            </div>
            <div class="item">
              <div class="label">現金申購/買回基數</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.CashNAV}}
              </div>
            </div>
          </div>
          <div class="items">
            <div class="item">
              <div class="label">申購/買回手續費<br>申購/買回交易費用</div>
              <div class="desc">
                {{item.fundInfo?.BasicInfo.SubRedemptionFee}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mob-hidden">
        <ul class="list">
          <li class="item">
            <p>基金名稱</p>
            <p>{{ item.fundInfo?.data?.info?.displayShortNameLocal }}</p>
          </li>
          <li class="item">
            <p>投資地區</p>
            <p>{{ item.fundInfo?.data?.info?.investmentArea }}</p>
          </li>
          <li class="item">
            <p>基金名稱(英文)</p>
            <p>{{ item.fundInfo?.data?.info?.displayShortName }}</p>
          </li>
          <li class="item">
            <p>基金組別</p>
            <p>{{ item.fundInfo?.data?.info?.categoryLocal }}</p>
          </li>
          <li class="item">
            <p>計價幣別</p>
            <p>{{ item.fundInfo?.data?.info?.classCurrencyLocal }}</p>
          </li>
          <li class="item">
            <p>基準指數</p>
            <p>{{ item.fundInfo?.data?.info?.primaryBenchMarkIndexLocal }}</p>
          </li>
          <li class="item">
            <p>風險評等</p>
            <p>{{ item.fundInfo?.data?.info?.riskLevel }}</p>
          </li>
          <li class="item">
            <p>基金經理人</p>
            <p>
              {{ item.fundInfo?.data?.info?.managerNameLocal }}({{
              item.fundInfo?.data?.info?.fundManagerStartDate
              | date : "yyyy/MM/dd"
              }})
            </p>
          </li>
          <li class="item">
            <p>理柏評級總回報(整體)</p>
            <p>
              <span class="number">{{
                item.fundInfo?.data?.info?.lipperRating == 0
                ? ""
                : item.fundInfo?.data?.info?.lipperRating
                }}</span>
            </p>
          </li>
          <li class="item">
            <p>基金類型</p>
            <p>
              {{ item.fundInfo?.data?.info?.fundCompanyNameLocal
              }}<a href="./fund/brand-detail?brandCode={{
                  item.fundInfo?.data?.info?.fundCompanyCode
                }}&TS_CD={{
                  item.fundInfo?.data?.fundID.substring(0, 1) == 'A'
                    ? '境內'
                    : '境外'
                }}" style="margin: 0 0 0 15px">詳細資訊</a>
            </p>
          </li>
        </ul>
      </div> -->
      <!-- 手機 -->
      <!-- <div class="pc-hidden">
        <ul class="list">
          <li class="item">
            <div class="card">
              <div class="item-title active">
                <p>
                  <span>{{
                    item.fundInfo?.data?.info?.displayShortNameLocal
                    }}</span>
                </p>
              </div>

              <div class="sub-list-box active">
                <ul class="sub-list">
                  <li class="sub-item">
                    <p>基金名稱(英文)</p>
                    <div>
                      <p>{{ item.fundInfo?.data?.info?.displayShortName }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>計價幣別</p>
                    <div>
                      <p>{{ item.fundInfo?.data?.info?.classCurrencyLocal }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>投資地區</p>

                    <div>
                      <p>{{ item.fundInfo?.data?.info?.investmentArea }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>基金組別</p>

                    <div>
                      <p>{{ item.fundInfo?.data?.info?.categoryLocal }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>風險評等</p>

                    <div>
                      <p>{{ item.fundInfo?.data?.info?.riskLevel }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>基準指數</p>

                    <div>
                      <p>
                        {{
                        item.fundInfo?.data?.info?.primaryBenchMarkIndexLocal
                        }}
                      </p>
                    </div>
                  </li>

                  <li class="sub-item">
                    <p>理柏評級總回報<br />(整體)</p>

                    <div>
                      <p>
                        <span class="number">{{
                          item.fundInfo?.data?.info?.lipperRating == 0
                          ? ""
                          : item.fundInfo?.data?.info?.lipperRating
                          }}</span>
                      </p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>基金經理人</p>

                    <div>
                      <p>
                        {{ item.fundInfo?.data?.info?.managerNameLocal }}({{
                        item.fundInfo?.data?.info?.fundManagerStartDate
                        | date : "yyyy/MM/dd"
                        }})
                      </p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>基金管理公司</p>

                    <div>
                      <p>
                        {{ item.fundInfo?.data?.info?.fundCompanyNameLocal
                        }}<br /><a href="./fund/brand-detail?brandCode={{
                            item.fundInfo?.data?.info?.fundCompanyCode
                          }}&TS_CD={{
                            item.fundInfo?.data?.fundID.substring(0, 1) == 'A'
                              ? '境內'
                              : '境外'
                          }}">詳細資訊</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div> -->
    </section>

    <section [id]="'anchor-' + item.CId" class="section-16" *ngIf="item.CType == '20'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <div class="search-wrap">
        <div class="calendar-item">
          <label>開始時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="fundDetailPriceReq.SDate" (ngModelChange)="
                setChartData(item.JsonColumns?.FundList)
              " placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <div class="calendar-item">
          <label>結束時間</label>
          <div class="calendar-wrap">
            <img class="calendar-icon" src="/assets/images/calendar.svg" alt="月曆圖示" />
            <p-calendar class="calendar" dateFormat="yy/mm/dd" [(ngModel)]="fundDetailPriceReq.EDate" (ngModelChange)="
                setChartData(item.JsonColumns?.FundList)
              " placeholder="年/月/日"></p-calendar>
          </div>
        </div>
        <!-- <button (click)="GetFundNAV()">查詢</button> -->
      </div>
      <div class="button-wrap">
        <button *ngFor="let data of enumDateList" [ngClass]="{'btn-active': DateName == data.Name}"
          (click)="selectTimeRange(data.Name, item.JsonColumns?.FundList)">{{data.Name}}</button>
      </div>

      <!-- <div class="input-box">
        <div class="input">
          <label>開始日期</label>
          <div class="date-box">
            <input type="date" [ngModel]="fundDetailPriceReq.SDate | date : 'yyyy-MM-dd'" (ngModelChange)="
                fundDetailPriceReq.SDate = $event;
                setChartData(item.JsonColumns?.FundList)
              " />
          </div>
        </div>
        <div class="input">
          <label>結束日期</label>
          <div class="date-box">
            <input type="date" [ngModel]="fundDetailPriceReq.EDate | date : 'yyyy-MM-dd'" (ngModelChange)="
                fundDetailPriceReq.EDate = $event;
                setChartData(item.JsonColumns?.FundList)
              " />
          </div>
        </div>

        <div class="fliter-list-box">
          <ul class="fliter-list">
            <ng-container *ngFor="
                let range of [
                  '今年以來',
                  '3個月',
                  '6個月',
                  '1年',
                  '2年',
                  '3年',
                  '5年',
                  '成立至今'
                ];
                let index = index
              ">
              <li class="fliter-item" (click)="selectTimeRange(range, item.JsonColumns?.FundList)"
                [ngClass]="{ active: timeRange === range }">
                {{ range }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div> -->

      <!-- <highcharts-chart *ngIf="isHighcharts" [options]="chartOptions" [(update)]="updateFlag" [Highcharts]="Highcharts" style="width: 100%;height: 400px; display: block;">
      </highcharts-chart> -->

      <div class="highcharts">
        <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
          [options]="chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
    </section>

    <!-- 基金配息 -->
    <section [id]="'anchor-' + item.CId" class="section-12" *ngIf="item.CType == '16'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <!-- 桌機 -->
      <div class="mob-hidden">
        <table>
          <tr>
            <th style="width: 50%;">基金代碼</th>
            <th style="width: 200%;">基金名稱</th>
            <th>除息日</th>
            <th>每單位配息金額</th>
            <!-- <th>年化配息率(少了參數)</th> -->
            <th>配息頻率</th>
          </tr>
          <tbody>
            <tr *ngFor="let FundDividend of item.fundPerformance">
              <th style="width: 50%;">
                <p>
                  {{ FundDividend.CNo }}
                </p>
              </th>
              <td style="width: 200%;">
                <p>{{ FundDividend.CFullName }}<span class="warning-text">{{FundDividend.CWarning}}</span></p>
              </td>
              <td>
                <p>{{ FundDividend.CExDate }}</p>
              </td>
              <td>
                <p>{{ FundDividend.CPerShare }}</p>
              </td>
              <!-- <td>
                <p>{{ FundDividend.annualDividendYieldDesc }}</p>
              </td> -->
              <td>
                <p>{{ FundDividend.CCgDividendTypeStr }}</p>
              </td>
            </tr>
          </tbody>

        </table>
      </div>

      <!-- 手機 -->
      <div class="pc-hidden">
        <ul class="list">
          <li class="item" *ngFor="
              let FundDividend of item.fundPerformance;
              let dividendIndex = index
            ">
            <div class="card">
              <div class="item-title" [ngClass]="{ active: expandIndex === dividendIndex }" (click)="
                  expandIndex =
                    expandIndex === dividendIndex ? -1 : dividendIndex
                ">
                <p>
                  <span>{{ FundDividend.fundName }}</span>
                </p>

                <img src="./assets/images/icon/arrow_top_block.svg" alt="" />
              </div>

              <div class="sub-list-box" [ngClass]="{ active: expandIndex === dividendIndex }">
                <ul class="sub-list">
                  <li class="sub-item">
                    <p>基金代碼</p>
                    <div>
                      <p>{{ FundDividend.fundID }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>除息日</p>
                    <div>
                      <p>{{ FundDividend.dividendDate }}</p>
                    </div>
                  </li>
                  <li class="sub-item">
                    <p>每單位配息金額</p>

                    <div>
                      <p>{{ FundDividend.dividendPerShareDesc }}</p>
                    </div>
                  </li>
                  <!-- <li class="sub-item">
                    <p>年化配息率</p>

                    <div>
                      <p>{{ FundDividend.annualDividendYieldDesc }}</p>
                    </div>
                  </li> -->
                  <li class="sub-item">
                    <p>配息頻率</p>

                    <div>
                      <p>{{ FundDividend.dividendFrequency }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <!-- 投資組合 -->
    <section [id]="'anchor-' + item.CId" class="section-12" *ngIf="item.CType == '19'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>

      <div class="pie-chart-wrap">
        <div class="pie-chart" *ngFor="let data of FundInvestment">
            <h2 class="sub-title sub-title-first">{{data.CChartName}}</h2>
            <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts"
                [constructorType]="chartConstructor" [options]="data.chartOptions" [(update)]="updateFlag"
                [oneToOne]="oneToOneFlag" [runOutsideAngular]="runOutsideAngular"
                style="width: 100%; height: 400px; display: block;"></highcharts-chart>

            <div class="chart-wrap">
                <div class="chart-label" *ngFor="let item of data.Data;let i = index">
                    <div class="point" [ngStyle]="{'background-color': colors[i]}"></div>
                    <div class="point-name">{{item.name}}</div>
                    <div class="point-value">{{item.y}}%</div>
                </div>
            </div>
        </div>
    </div>
    <div class="tip">資料來源：街口投信、Lipper 資料日期: {{FundInvestment && FundInvestment.length && FundInvestment[0].CDataDate}}</div>
    </section>

    <section class="section-17" [id]="'anchor-' + item.CId" *ngIf="item.CType == '21'">
      <div class="section-title-box" *ngIf="item.JsonColumns?.TitleEnable">
        <p class="section-sub-title" *ngIf="item.JsonColumns?.SubTitle">
          {{ item.JsonColumns?.SubTitle }}
        </p>
        <p class="section-title">
          {{ item.CTitle }}
        </p>
      </div>
      <div class="qa-info">
        <div class="wrapper">
          <ng-container *ngFor="let item of qaList">
            <p-panel class="p-panel" [header]="item.CQuestion" aria-label="QA問題" aria-labelledby="QA問題"
              [collapsed]="true" [toggleable]="true">
              <ng-template pTemplate="headericons">
                <img class="black-arrow" src="/assets/images/open.svg" alt="open-arrow" />
              </ng-template>
              <div class="panel-item">
                <div class="desc" [innerHtml]="item.CAnswer|html"></div>
              </div>
            </p-panel>
          </ng-container>
        </div>
      </div>
      <!-- <ul class="qa-list">
        <li class="qa-item" *ngFor="let item of qaList">
          <ul class="qa-sub-list">
            <ng-container *ngFor="let item2 of item.qa">
              <li class="qa-sub-item" [ngClass]="{ active: item2.isActive }" (click)="item2.isActive = !item2.isActive">
                <div class="qa-q">
                  <p>{{ item2.q }}<span></span></p>
                </div>
                <div class="qa-a">
                  <p [innerHTML]="item2.a | html"></p>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul> -->
    </section>
  </ng-container>

  <!-- <section class="section-04">
    <ul class="list">
      <li
        class="item"
        *ngFor="
          let item of [
            './assets/images/fake/event-site/img-04.png',
            './assets/images/fake/event-site/img-05.png'
          ]
        "
      >
        <div class="left">
          <img class="img" [src]="item" alt="" />
        </div>

        <div class="right">
          <p class="text">
            2019年亞高收債市下半年展望：<br />
            深度訪談柏瑞投資新興市場<br />
            固定收益聯席投資主管劉曙明（Arthur Lau）<br />
            <br />
            深度訪談柏瑞投資新興市場固定收益聯席投資主管劉曙明(ArthurLau)，<br />
            精選出客戶對亞高收債市所關心的問題，<br />
            包括：中國經濟如果真的轉弱，對亞高收債（亞洲高收益債基金）下半年的影響是什麼？需要擔心....
          </p>
        </div>
      </li>
    </ul>
  </section> -->

  <!-- <section class="section-06">
    <ul class="list">
      <swiper
        [breakpoints]="{
          '0': { slidesPerView: 1, slidesPerGroup: 1 },
          '768': { slidesPerView: 2, slidesPerGroup: 2 },
          '992': { slidesPerView: 3, slidesPerGroup: 3 }
        }"
      >
        <ng-template swiperSlide *ngFor="let item of ['', '', '', '', '', '']">
          <li class="item">
            <div class="card">
              <img
                class="img"
                src="./assets/images/fake/event-site/img-07.png"
                alt=""
              />

              <p class="title">美國GDP萎縮，股市持續震盪</p>

              <div class="content">
                2019年亞高收債市下半年展望：<br />
                深度訪談柏瑞投資新興市場<br />
                固定收益聯席投資主管劉曙明（Arthur Lau）<br />
                <br />
                深度訪談柏瑞投資新興市場固定收益聯席投資主管劉曙明(ArthurLau)，<br />
                精選出客戶對亞高收債市所關心的問題，<br />
                包括：中國經濟如果真的轉弱，對亞高收債（亞洲高收益債基金）下半年的影響是什麼？需要....
              </div>
            </div>
          </li>
        </ng-template>
      </swiper>
    </ul>
  </section> -->

  <!-- 未完工先隱藏 -->
  <ng-container>
    <!-- 投資組合配置  先隱藏-->
    <section class="section-15" *ngIf="false">
      <div class="section-title-box">
        <p class="section-sub-title">fund</p>
        <p class="section-title">投資組合配置</p>
      </div>

      <div class="content">
        <div class="left">
          <div class="canvasBox">
            <highcharts-chart *ngIf="isHighcharts" [(update)]="updateFlag2" [options]="chartOptions2" [Highcharts]="Highcharts2"
              style="width: 100%">
            </highcharts-chart>
          </div>

          <div class="paigraph-list-box">
            <ul class="paigraph-list">
              <li class="paigraph-item" *ngFor="let item of chartscolors2">
                <div class="colorbox">
                  <span [ngStyle]="{ 'background-color': item }"></span>
                </div>
                <div class="name" title="電子零組件業">台灣</div>
                <div class="prsent">26.54%</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="right">
          <div class="canvasBox">
            <highcharts-chart *ngIf="isHighcharts" [(update)]="updateFlag2" [options]="chartOptions2" [Highcharts]="Highcharts2"
              style="width: 100%">
            </highcharts-chart>
          </div>

          <div class="paigraph-list-box">
            <ul class="paigraph-list">
              <li class="paigraph-item" *ngFor="let item of chartscolors2">
                <div class="colorbox">
                  <span [ngStyle]="{ 'background-color': item }"></span>
                </div>
                <div class="name" title="電子零組件業">台灣</div>
                <div class="prsent">26.54%</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
